export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [6,3,7,8,10,12,13,14,15,18];

export const dictionary = {
		"/(home)": [32,[6]],
		"/(cart)/cart": [28,[3]],
		"/(cart)/cart/checkout": [~29,[3,4]],
		"/(cart)/cart/method": [30,[3,5]],
		"/(cart)/cart/payment": [31,[3]],
		"/(home)/categories": [33,[6]],
		"/(home)/categories/[id]/products": [34,[6,7]],
		"/(home)/location": [35,[6,8]],
		"/(main)/order/hook/[id]": [40,[10]],
		"/(main)/outlets": [41,[10,11]],
		"/(main)/outlets/[id]": [42,[10,11,12]],
		"/(main)/outlets/[id]/categories/[cid]/products": [43,[10,11,12,13]],
		"/(main)/outlets/[id]/map": [44,[10,11,12]],
		"/(main)/outlets/[id]/product/[barcode]": [45,[10,11,12]],
		"/(main)/outlets/[id]/search": [46,[10,11,12,14]],
		"/(home)/privacy": [36,[6]],
		"/(main)/profile": [47,[10,15]],
		"/(main)/profile/favorites": [48,[10,15,16]],
		"/(main)/profile/logout": [49,[10,15]],
		"/(main)/profile/purchases": [50,[10,15,17]],
		"/(main)/profile/purchases/[id]": [51,[10,15,17,18]],
		"/(main)/profile/purchases/[id]/method": [52,[10,15,17,18]],
		"/(main)/profile/purchases/[id]/pay": [53,[10,15,17,18]],
		"/(main)/profile/settings": [54,[10,15]],
		"/(main)/profile/vouchers": [55,[10,15,19]],
		"/(main)/profile/vouchers/transactions/[id]": [56,[10,15,19,20]],
		"/(main)/profile/wishlists": [57,[10,15,21]],
		"/(home)/search": [37,[6,9]],
		"/(home)/search/[barcode]": [38,[6,9]],
		"/(auth)/session/bypass": [22,[2]],
		"/(auth)/session/confirm": [23,[2]],
		"/(auth)/session/identity/facebook": [24,[2]],
		"/(auth)/session/identity/google": [25,[2]],
		"/(auth)/session/login": [26,[2]],
		"/(auth)/session/logout": [27,[2]],
		"/(home)/terms": [39,[6]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';